@import "~govuk-frontend/dist/govuk/base";
@import '../../assets/scss/colours';

.box {
  @media (max-height: 768px) {
    height: auto;
  }

  @include govuk-media-query($from: tablet) {
    height: 60vh;
  }
}

.wrapper {
  font-size: 1rem;
  word-wrap: break-word;
	padding: 3rem 4rem;
  margin: 3rem auto;
  background-color: white;
  border-radius: 0.75rem;
  box-shadow:  0px 3px 5px 0px rgba(5, 6, 7, 0.3);
}