@import '../colours';

$chevron-border-colour: $govuk-secondary-text-colour;

.govuk-breadcrumbs {
    @include govuk-text-colour;
}

.govuk-breadcrumbs__list-item {
    &:before {
        border-color: $chevron-border-colour;
  }
}
