@import "~govuk-frontend/dist/govuk/base";
@import '../../assets/scss/colours';

.pagination{
    .govuk-link{
        padding: 0 0.5rem;
        text-decoration: underline;
        color: $blue;
        &:focus {
          border: none;
          border-color: transparent;
          outline: none;
          color: $blue; 
          background-color: transparent;
          box-shadow: inset 0 0 0 1px transparent;
          padding: 0.6rem;
        }
        &:hover, :link {
          color: $darkblue  
        }
      }
}
