@import "~govuk-frontend/dist/govuk/base";
@import '../../../assets/scss/colours';

.govuk-label {
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	text-align: start;

	@include govuk-media-query($from: desktop) {
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
	}
}

.info-text {
	background-color: #dff0d8;
	padding: 1rem;
}

.govuk-checkboxes__label {
	line-height: 1;

	@include govuk-media-query($from: desktop) {
		line-height: 1.25;
	}
}	

.govuk-panel--confirmation {
	text-align: center;

	@include govuk-media-query($from: desktop) {
		& .govuk-panel__body {
			font-size: 2.25rem;
		}
	}

}

.recaptcha-widget {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: start;
}

.accordion > input[type="checkbox"] {
	position: absolute;
	left: -100vw;
}
  
.accordion .content {
	overflow-y: hidden;
	height: 0;
	transition: height 0.3s ease;
	visibility: hidden;
	margin-top: -2rem;
  }
  
.accordion > input[type="checkbox"]:checked ~ .content {
	height: auto;
	overflow: visible;
	visibility: visible;
  }
  
.accordion label {
	display: block;
	font-size: 1rem;
	font-weight: lighter;
	cursor: pointer;
  }
  
.accordion > input[type="checkbox"]:checked ~ .content {
	padding: 15px;

  }
  
.accordion .handle {
	margin: 0;
	font-size: 1.125rem;
	line-height: 1.2rem;
  }

#next-button {
	margin: 0 15px 0 15px;
}
