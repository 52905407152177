@import "~govuk-frontend/dist/govuk/base";
@import '../../../../assets/scss/colours';

#address__lookup {
  .address__lookup {
    display: flex;
    flex-direction: column;

    @include govuk-media-query($from: desktop) {
      flex-direction: row;
    }

    .flex-left {
      margin: 0;
      padding: 0;

      @include govuk-media-query($from: desktop) {
        width: 70%;
      }
    }
    
    .flex-right {
      margin: 0;
      padding: 0;

      @include govuk-media-query($from: desktop) {
        width: 30%;
        text-align: end;
      }
    }

    .govuk-link {
      &:hover {
        text-decoration: none;
      }
    }

    .govuk-input {
      margin:  0.5rem 0;
      
      @include govuk-media-query($from: desktop) {
        margin: 0;
      }
    }
  }

  .address__loading {
    font-size: 1.125rem;
    padding-left: 0.5rem;
  }

  .address__panel {
    min-height: 0;
    max-height: 8.75rem;
    overflow-y: scroll;
    border: 0.125rem solid #B1B8BF;
    margin: -1.2rem -0.825rem;
    font-size: 1rem;
    -webkit-transition: all 2s ease-out; 
    -moz-transition: all 2s ease-out; 
    -o-transition: all 2s ease-out; 
    transition: all 2s ease-out;

    @include govuk-media-query($from: desktop) {
      font-size: 1.125rem;
      margin: -0.75rem -0.8125rem;
    } 

    &-list {
      padding: 0.25rem 0.75rem;
      z-index: 1;

      &:hover {
        background-color: $borderDisabled;
        cursor: pointer;
      }

      &:focus {
        outline: none;
        border: 0.06025rem solid #B1B8BF;
        background-color: $borderDisabled;
      }
    }
  }
  
  .hidden {
    display: none;
  }

  .show {
    display: block;
  }
  
  .govuk-error-message {
    margin-bottom: 0;
  }

}

