@import '../colours';

$govuk-radios-focus-width: $govuk-focus-width + 1px;
$conditional-border-width: $govuk-border-width-narrow;

.govuk-radios__label:before {
    border: $govuk-border-width-form-element solid currentcolor;
    border-radius: 50%;
    background: transparent;
}

.govuk-radios__label:after {
    border: govuk-spacing(2) solid currentcolor;
    background: currentcolor;
}

.govuk-radios__input:focus + .govuk-radios__label:before {
    @media screen and (forced-colors: active), (-ms-high-contrast: active) {
      outline-color: Highlight;
    }

    box-shadow: 0 0 0 $govuk-radios-focus-width $govuk-focus-colour;
  }

  .govuk-radios__divider {
    @include govuk-text-colour;
  }

  .govuk-radios__conditional {
    border-left: $conditional-border-width solid $govuk-border-colour;
  }

  .govuk-radios--small {
    .govuk-radios__item:hover .govuk-radios__input:not(:disabled) + .govuk-radios__label:before {
      box-shadow: 0 0 0 $govuk-hover-width $govuk-hover-colour;
    }

    .govuk-radios__item:hover .govuk-radios__input:focus + .govuk-radios__label:before {
      box-shadow:
        0 0 0 $govuk-radios-focus-width $govuk-focus-colour, // 1
        0 0 0 $govuk-hover-width        $govuk-hover-colour; // 2
    }

    @media (hover: none), (pointer: coarse) {
      .govuk-radios__item:hover .govuk-radios__input:focus + .govuk-radios__label:before {
        box-shadow: 0 0 0 $govuk-radios-focus-width $govuk-focus-colour;
      }
    }
}