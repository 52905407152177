@import "~govuk-frontend/dist/govuk/base";
@import "../../assets/scss/colours";

.header_mobile_view {
    padding: 1rem 0 1rem 0 ;
}

.acting_on_name {
    word-wrap: break-word;
    word-break: break-word;
    @include govuk-media-query($until: tablet) {
        padding-left: 2rem;
      }
    @include govuk-media-query($from: tablet) {
        padding-left: 2.5rem;
      }
}

.user_icon {
  color: var(--tenant-brand-colour, $access);
  width: 2rem;
  height: 2rem;
  margin: 0.25rem;
  @include govuk-media-query($until: tablet) {
    width: 1.5rem;
    height: 1.5rem;
  }
}