@import "~govuk-frontend/dist/govuk/base";
@import '../../assets/scss/colours';
.privacy-background {
  background-color: white;
  padding: 3rem;
}

.external-links {
    font-size: 1rem;
    padding: 0.5rem;
}

#privacyNotice a:link {
  color: $access;
}

#privacyNotice a:visited {
  color: $access;
}

.main__container {
  @include govuk-media-query($until: tablet ) {
padding: 2rem;
  }
}

.govuk-table {
  @include govuk-media-query($until: tablet ) {
    font-size: 0.5rem;
      }
}

.updated-text {
  font-size: 1rem;
}

hr {
  border: 1px solid $access;
}